import React,{Component} from 'react';
import { Redirect } from 'react-router-dom';
import { unmountComponentAtNode } from "react-dom";
import {base, appDatabasePrimaryFunctions} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import Confetti from 'react-confetti';
import Slider from '@material-ui/core/Slider';
import appBackgroundImage from '../../styles/images/trivia_background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import { getDistance } from 'geolib';
import Loading from '../utils/Loading';
import {valueDoesExist, validateEmail} from '../utils/HelpfulFunction';
import ScoreComponent from '../small_components/ScoreComponent';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';


class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userEmail: sessionStorage.getItem('userEmail') || false,
          alerted: false,
          tenantRules: {},
          width: 0,
          height: 0,
          question: {},
          questionAnswered: false,
          formFilledOut: false,
          alreadyUpdate: "",
          currentGameId: null,
          currentGameTime: false,
          rotationStarted: false,
          optedIntoEmail: {},
          allowVoting: false,
          currentAnswerId: null,
          correctAnswer: false,
          firstStart: false,
          sliderValue: null,
          locationChecking: true,
          loading: true,
          startConfetti:false
        };
        this.logout = this.logout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
        // this.toggle = this.toggle.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element')
      const target = document.getElementById('react-confirm-alert')
      if(target){
        unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg')
      if(svg){
        svg.parentNode.removeChild(svg)
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount() {
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.logUserActivity(key);
            this.removeReactAlert();
            this.setState({
              currentGameId:key,
              sliderValue: null,
              teamOneScore: null,
              teamTwoScore: null,
              startConfetti:false
            })
          }
        }
      });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(!validateEmail(userEmail)){
        base64EncodedEmail = userEmail;
      }

      this.gameStartTimeRef = base.bindToState('currentGame/timeStamp', {
        context: this,
        state: 'currentGameTime',
      })

      this.firstStartRef = base.bindToState('currentGame/firstStart', {
        context: this,
        state: 'firstStart',
      })

      this.allowVotingRef = base.bindToState('currentGame/active', {
        context: this,
        state: 'allowVoting',
      })

      this.correctAnswerRef = base.listenTo('currentGame/correctAnswer', {
        context: this,
        state: 'correctAnswer',
        then(data){
          if(typeof data === "object"){
            data = false
          }
          if(data){
            this.alertAboutWinningStatus(data)
          }
          this.setState({
            correctAnswer: data
          })
        }
      })

      this.questionRef = base.bindToState('currentGame/question', {
        context: this,
        state: 'question'
      })

      this.userAnswersLengthRef = base.bindToState('userAnswers', {
        context: this,
        state: 'userAnswers',
        asArray: true,
      })

      this.currentResponseRef = base.listenTo('userAnswers/'+base64EncodedEmail+'/answer', {
        context: this,
        then(answerId){
          if(this.props.variables && this.props.variables.collectDistance && typeof answerId != "number" && typeof answerId != "string"){
            this.checkUsersLocation();
            this.setState({
              questionAnswered: false
            })
          } else if(typeof answerId === "number") {
            this.setState({
              locationChecking: false,
              loading:false,
              questionAnswered: true,
              sliderValue: answerId
            })
          } else if(this.state.question && this.state.question.questionType === "score" && answerId && typeof answerId === "string") {
              const splitAnswer = answerId.split(" - ");
              this.setState({
                loading:false,
                locationChecking: false,
                questionAnswered: true,
                sliderValue: null,
                teamOneScore:splitAnswer[0],
                teamTwoScore:splitAnswer[1]
              })
          } else if(answerId && typeof answerId === "string") {
            this.setState({
              locationChecking: false,
              loading:false,
              questionAnswered: true,
              sliderValue: answerId
            })
          } else {
            this.setState({
              locationChecking: false,
              loading:false,
              questionAnswered: false,
              sliderValue: null,
              teamOneScore:null,
              teamTwoScore:null
            })
          }
        }
      })

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0);
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    handleTimeChange(event){
      this.setState({
        sliderValue: event
      })
    }

    changeMilesToMeters(milesToConvert){
    return milesToConvert*1609.344;
  }

    getLocation(){
      const stringConstants = this.props.stringConstants;
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(stringConstants.GEOLOCATIONNOTSUPPORTED);
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject(stringConstants.NEEDBROWSERPERMISSION);
                } else {
                  console.log(err.message);
                  reject(stringConstants.UNKNOWNERROROCCURED);
                }
              }
          );
        }
      })
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    getLocationPermission(){
      const stringConstants = this.props.stringConstants || {};
      const locationErrorTitle = stringConstants.LOCATIONERRORHEADER;
      this.getLocation().then(distance_meters => {
        sessionStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(!this.props.variables.canPlayOutside && distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false
          });
          this.logUserActivity(this.state.currentGameId);
        } else if(this.props.variables.canPlayOutside && distance_meters > allowed_distance) {
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false
          });
          this.logUserActivity(this.state.currentGameId);
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          let locationErrorMessage = "Too far from game area to participate!";
          if(this.props.variables.canPlayOutside){
            locationErrorMessage = "Too close game area to participate!";
          }
          if(this.props.variables.notAcceptableLocationMessage){
            locationErrorMessage = this.props.variables.notAcceptableLocationMessage;
          }
          let notAcceptableLocationHeader = locationErrorTitle;
          if(this.props.variables.notAcceptableLocationHeader){
            notAcceptableLocationHeader = this.props.variables.notAcceptableLocationHeader;
          }
          confirmAlert({
            title: notAcceptableLocationHeader,
            variables: this.props.variables,
            message: locationErrorMessage,
            buttons: [
              {
                label: stringConstants.RETRYTEXT,
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        console.log(error);
        this.setState({
          modal:false,
          loading:false
        });
        sessionStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: stringConstants.RETRYTEXT,
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const stringConstants = this.props.stringConstants;
      const variables = this.props.variables;
      const locationPermissions = sessionStorage.getItem('locationPermissions');
      if(!this.props.variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: stringConstants.YESCONFIRMBUTTONTEXT,
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    componentWillUnmount() {
      base.removeBinding(this.allowVotingRef);
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.currentResponseRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.questionRef);
      base.removeBinding(this.gameStartTimeRef);
      base.removeBinding(this.firstStartRef);
      if(this.currentGameFormFilledRef){
        base.removeBinding(this.currentGameFormFilledRef);
      }
      base.removeBinding(this.userAnswersLengthRef);
      base.removeBinding(this.correctAnswerRef);
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
      sessionStorage.removeItem('userEmail');
      sessionStorage.removeItem('verifiedAge');
      sessionStorage.removeItem('birthday');
      sessionStorage.removeItem('locationPermissions');
      this.setState({
        userEmail:false
      })
    }

  logUserActivity(currentGame){
      const vm = this;
      if(currentGame){
        const userEmailToBase64 = btoa(this.state.userEmail);
        const gameid = currentGame;
        base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          data:gameid,
          then(err){
            if(!err){
              //this doesnt do anything right now
              vm.setState({
                alreadyUpdate: gameid,
              })
              console.log("user game logged!")
            }
          }
        })
      }
    }

    onClickSlideAnswer(){
      const stringConstants = this.props.stringConstants || {};
      const scoreTeamOne = this.state.scoreTeamOne;
      const scoreTeamTwo = this.state.scoreTeamTwo;
      let firstPartTime = this.state.firstPartTime || "";
      let secondPartTime = this.state.secondPartTime || "";
      const question = this.state.question || {};
      let answer = this.state.sliderValue;
      if(!valueDoesExist(answer) && question.questionType !== "score" && question.questionType !== "time"){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.NOANSWERTEXT,
          message: stringConstants.PLEASESELECTANSWERFIRSTTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      if(!this.state.allowVoting){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.PREDICTIONSTOPPEDHEADER,
          message: stringConstants.PREDICTIONSTOPPEDTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      if(this.state.question.questionType === "time"){
        if(secondPartTime.length === 1){
          secondPartTime = secondPartTime + "0";
        }
        if(firstPartTime.length === 1){
          firstPartTime = "0" + firstPartTime;
        }
        answer = (firstPartTime || "00") + ":" + (secondPartTime || "00")
      } else {
        answer = parseInt(answer)
      }
      if(question.questionType === "score"){
        if(!scoreTeamOne || !scoreTeamTwo){
          confirmAlert({
            variables: this.props.variables,
            title: stringConstants.MISSINGINFOTEXT,
            message: stringConstants.FILLINSCORETEXT,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
        answer = scoreTeamOne + " - " + scoreTeamTwo;
      }
      if(typeof this.state.correctAnswer != "string") {
        confirmAlert({
          variables: this.props.variables,
          title: answer,
          changeTitleSize: "50px",
          message: stringConstants.UPDATEDAREYOUSURETEXT,
          buttons: [
            {
              label: stringConstants.CONFIRMTEXT,
              onClick: () => {
                let base64EncodedEmail = btoa(this.state.userEmail);
                let postToUserAnswers = {};
                postToUserAnswers["/userAnswers/" + base64EncodedEmail + "/answer"] = answer;
                postToUserAnswers["/userAnswers/" + base64EncodedEmail + '/timeStamp'] = new Date().getTime().toString();
                postToUserAnswers["/userAnswers/" + base64EncodedEmail + '/uid'] = base64EncodedEmail;
                const vm = this;
                appDatabasePrimaryFunctions.ref().update(postToUserAnswers, function (error) {
                  if (!error) {
                    console.log("Answer Clicked");
                    vm.logUserActivity(vm.state.currentGameId);
                    const afterFinishPredictingHeader = vm.props.variables.afterFinishPredictingHeader || stringConstants.FINISHEDTEXT;
                    const afterFinishPredictingBody = vm.props.variables.afterFinishPredictingBody || stringConstants.YOUANSWEREDALLQUESTIONSTEXT;
                    confirmAlert({
                      title: afterFinishPredictingHeader,
                      variables: vm.props.variables,
                      message: afterFinishPredictingBody,
                      buttons: [
                        {
                          label: stringConstants.OKTEXT,
                          onClick: () => {
                            console.log("ON CLICK")
                          }
                        }
                      ]
                    });
                    if(secondPartTime || firstPartTime){
                      vm.setState({
                        secondPartTime: secondPartTime,
                        firstPartTime: firstPartTime
                      })
                    }
                  } else {
                    console.log(error)
                  }
                })
              }
            },
            {
              label: stringConstants.CHANGETEXT,
            }
          ]
        })
      } else {
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.PREDICTIONANSWEREDTEXT,
          message: stringConstants.PREDICTIONHASALREADYBEENANSWEREDTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
      }
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const questionColor = tenantVariables.questionColor;

      return(
        <>
            <div className="hero-text-container">
              <img src={frontLogoImage} className="main-hero-image full-image-container" alt=""/>
            </div>
            <p style={{display: tenantVariables.playingText ? '' : "none", color:questionColor, fontSize: 20, fontWeight: 700, padding: 26}}>
              {tenantVariables.playingText}
            </p>
        </>
        )
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      const question = this.state.question;
      const questionAnswered = this.state.questionAnswered;
      const topLeftImage = tenantVariables.topLeftImage || sampleAppTopImage
      const secondaryColor = tenantVariables.secondaryColor;
      const primaryColor = tenantVariables.primaryColor;
      let sliderValue = this.state.sliderValue;
      const questionColor = tenantVariables.questionColor;
      const outlineColor = tenantVariables.buttonOutlineColor;
      const sliderColor = tenantVariables.sliderColor;
      const correctAnswer = this.state.correctAnswer;
      const belowCorrectText = tenantVariables.belowCorrectText || "THE GAME HAS ENDED. COME BACK NEXT GAME!"
      let answerMin = 1;
      let answerMax = 100;
      let answerSteps = 1;
      if(question.questionType === "slider"){
        answerMin = Number.parseInt(question.answerMin);
        answerMax = Number.parseInt(question.answerMax);
        answerSteps = Number.parseInt(question.answerSteps);
      }
      let firstPartTime = this.state.firstPartTime;
      let secondPartTime = this.state.secondPartTime;
      if(question.questionType === "time"){
        if(sliderValue && !firstPartTime && !secondPartTime){
          firstPartTime = sliderValue.split(":")[0]
          secondPartTime = sliderValue.split(":")[1]
        }
      }
      // else
        if(question.questionType === "slider" && !sliderValue) {
          sliderValue = answerMin || 0
      }

      return(
        <>
          <div className="creator-awards-logo-playscreen user">
            <img src={topLeftImage} alt="" style={{marginBottom:"0"}}/>
          </div>
          <div className="card" style={{borderRadius:"20px", marginTop:'0',paddingTop:'0', marginLeft:"auto", marginRight: "auto"}}>
            <div className="top-text">
              <p className="top-text-mobile" style={{fontSize: 23, wordWrap: "break-word", fontWeight:700, color:questionColor}}>{question.questionText}</p>
            </div>
            <div className="top-text" style={{display: questionAnswered? "": "none"}}>
              <p className="top-text-mobile"><span className="emphasizedTextAnswers" style={{backgroundColor:"transparent", color:questionColor, fontSize:20}}>{stringConstants.MYPREDICTIONTEXT}</span></p>
            </div>
            {question && question.questionType === "score" &&
              <ScoreComponent question={question} questionAnswered={questionAnswered} questionColor={questionColor} handleChange={(e)=> this.handleChange(e)} teamOneScore={this.state.teamOneScore} teamTwoScore={this.state.teamTwoScore}/>
            }
            <div className="top-text" style={{display: question && question.questionType === "slider"? "": "none", marginBottom:10}}>
              <p className="top-text-mobile" style={{fontSize: 80, lineHeight: 0.5, wordWrap: "break-word", fontWeight:700, color:questionColor}}>
                {this.state.sliderValue || 0}
                <br/>
                <span style={{fontSize:30}}>{question.answerLabel}</span>
              </p>
            </div>
            {!questionAnswered && question && question.questionType === "write_in" &&
            <div className="top-text">
              <p className="top-text-mobile" style={{fontSize: 15, wordWrap: "break-word", fontWeight:700, color:questionColor}}>{stringConstants.BELOWWRITEINTEXT}</p>
            </div>
            }
            {question && question.questionType === "write_in" &&
            <div style={{textAlign:"center", maxWidth:"100%"}}>
              <input
                  value={sliderValue}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  placeholder="0"
                  className="writeInStyles"
                  style={{color:questionColor, width:"100%", fontSize: "5rem"}}
                  onChange={(e)=> {
                    if(e.target.value && e.target.value.length > 5){
                      e.target.value = e.target.value.substring(0,5);
                    }
                    this.handleChange(e)
                  }}
                  name="sliderValue"
                  autoComplete="off"
                  readOnly={questionAnswered}
              />
            </div>
            }
            {question && question.questionType === "time" &&
            <div className="container">
              <div className="row" style={{flexWrap: "nowrap", justifyContent: "center"}}>
                    <span>
                      <input
                          value={firstPartTime}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          type="text"
                          placeholder="00"
                          size="2"
                          style={{color:questionColor, textAlign:"right"}}
                          name="firstPartTime"
                          className="writeInStyles"
                          autoComplete="off"
                          readOnly={questionAnswered}
                          onChange={(e)=>{
                            if(e.target.value && e.target.value.length > 2){
                              e.target.value = e.target.value.substring(0,2);
                            }
                            this.handleChange(e)
                          }}
                      />
                    </span>
                <span className="writeInStyles">
                      :
                    </span>
                <>
                  <input
                      value={secondPartTime}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      size="2"
                      placeholder="00"
                      style={{color:questionColor, textAlign:"left"}}
                      type="text"
                      name="secondPartTime"
                      className="writeInStyles"
                      autoComplete="off"
                      readOnly={questionAnswered}
                      onChange={(e)=>{
                        if(e.target.value && e.target.value.length > 2){
                          e.target.value = e.target.value.substring(0,2);
                        }
                        this.handleChange(e)
                      }}
                  />
                </>
              </div>
            </div>
            }
            {question && question.answerImage &&
              <div style={{width: "100%"}}>
                <img src={question.answerImage} width="100%" height="auto" alt=""/>
              </div>
            }
            {!questionAnswered && question && question.questionType === "slider" &&
              <div className="top-text">
                <p className="top-text-mobile" style={{fontSize: 15, wordWrap: "break-word", fontWeight:700, color:questionColor}}>{stringConstants.SLIDEBARTOCHOOSETEXT}</p>
              </div>
            }
            {questionAnswered && (sliderValue || this.state.teamOneScore || this.state.teamTwoScore) && correctAnswer &&
              <div style={{marginTop:15}}>
                <span style={{paddingRight: 10, paddingLeft: 10, fontSize: 40, fontWeight: 900, backgroundColor: this.state.userCorrect?"#228B22":"#E60000"}}>{this.state.userCorrect? stringConstants.CORRECTTEXT:stringConstants.INCORRECTTEXT}</span>
                <p style={{marginTop:15, color:questionColor}}>{belowCorrectText}</p>
              </div>
            }
            <Slider
                value={sliderValue}
                onChange={(value, newValue)=>this.setState({sliderValue:newValue})}
                aria-labelledby="discrete-slider"
                step={answerSteps}
                marks={false}
                min={answerMin}
                max={answerMax}
                style={{display: !questionAnswered && question && question.questionType === "slider"? "": "none", color: sliderColor}}
            />
              <style dangerouslySetInnerHTML={{
                  __html: [
                    'input::-webkit-input-placeholder {',
                      'color: ' + questionColor + ' !important;',
                    '}',
                    'input:-moz-placeholder {',
                      'color: ' + questionColor + ' !important;',
                    '}',
                    'input::-moz-placeholder {',
                      'color: ' + questionColor + ' !important;',
                    '}',
                    'input:-ms-input-placeholder {',
                      'color: ' + questionColor + ' !important;',
                    '}'
                  ].join('\n')
              }}/>
            <button onClick={()=>this.onClickSlideAnswer()} style={{margin: 'auto', marginTop: 10, width:210, height: 66, fontSize:36, borderColor: outlineColor, backgroundColor: primaryColor, color: secondaryColor, display: questionAnswered? "none": ""}} className="btn btn-play">{stringConstants.CHOOSETEXT}</button>
            <div className="mobilehide" style={{height:"100px", width:"1px"}}/>
          </div>
        </>
        )
    }

  alertAboutWinningStatus(correctAnswer){
      const stringConstants = this.props.stringConstants || {};
      const userEmail = this.state.userEmail;
      const base64EncodedEmail = btoa(userEmail);
      base.fetch('emailsSent/'+base64EncodedEmail, {
        context: this,
        then(data){
          const variables = this.props.variables;
          let sliderValue = this.state.sliderValue;
          const teamOneScore = this.state.teamOneScore;
          const teamTwoScore = this.state.teamTwoScore;
          const scoreAnswer = teamOneScore + " - " + teamTwoScore;
          if(this.state.question.questionType === "score"){
            sliderValue = scoreAnswer;
          } else if(this.state.question.questionType !== "time"){
            correctAnswer = parseInt(correctAnswer)
            sliderValue = parseInt(sliderValue)
          }
          if(valueDoesExist(sliderValue) && this.state.questionAnswered){
            let alertHeader = variables.winnerPrizeHeader || "You Win!";
            let alertText = variables.winnerPrizeText || "Check your email for a prize!";
            let correct = true;
            if(sliderValue === correctAnswer && data.type ==="other"){
              alertHeader = variables.winnerNoPrizeHeader || "You Win!";
              alertText = variables.winnerNoPrizeText || "Check your email for a prize!";
            } else if(sliderValue === correctAnswer && !data.name){
              alertHeader = variables.winnerNoPrizeHeader || "You Were Correct!";
              alertText = variables.winnerNoPrizeText || "Unfortunately you did not win a prize this time!";
            } else if(sliderValue !== correctAnswer){
              alertHeader = variables.missedHeader || "Oh No!";
              alertText = variables.missedText || "Your prediction was incorrect! Come back soon and try again";
              correct = false
            }
            if(correct){
              this.setState({
                startConfetti:true,
                userCorrect: correct
              })
            } else {
              this.setState({
                userCorrect: correct
              })
            }
            const vm = this;
            confirmAlert({
              variables: variables,
              title: alertHeader,
              message: alertText,
              buttons: [
                {
                  label: stringConstants.OKTEXT,
                  onClick: () => {
                    vm.setState({
                      startConfetti:false
                    })
                  }
                }
              ]
            })
          } else {
            confirmAlert({
              variables: variables,
              title: stringConstants.GUESSINGOVERTEXT,
              message: stringConstants.NOMOREPREDICTIONSTEXT,
              buttons: [
                {
                  label: stringConstants.OKTEXT,
                }
              ]
            })
          }
        }
      })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if((prevState.answered && !this.state.answered) || (prevState.sliderValue && !this.state.sliderValue) || (prevState.teamOneScore && !this.state.teamOneScore) || (prevState.teamTwoScore && !this.state.teamTwoScore)){
        this.removeReactAlert();
        this.logUserActivity(this.state.currentGameId);
        this.setState({
          sliderValue: null,
          teamOneScore: 0,
          teamTwoScore: 0,
          firstPartTime: "",
          secondPartTime: "",
          startConfetti:false
        })
      }
    }

    render() {
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const stringConstants = this.props.stringConstants || {};
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      let rulesPopUpText = tenantRules.rulesPopUpText;
      let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
      const link = tenantRules.rulesAndRegsLink;
      const rulesShowInApp = tenantRules.rulesShowInApp || false;
      if(rulesShowInApp) {
        if(!rulesShowInAppPopUpText && rulesPopUpText){
          rulesShowInAppPopUpText = rulesPopUpText;
        }
        if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
          rulesShowInAppPopUpHeader = rulesPopUpHeader;
        }
      }
      if(!verifiedAge){
        let urlToGo = "/age_gate";
        let langauge = "/";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(splitWindowLocation.length > 1){
          langauge += splitWindowLocation[1];
          urlToGo += langauge
        }
        return (
            <Redirect to={urlToGo} />
        )
      }
      if(!this.state.userEmail){
        let redirectString = "/login";
        let langague = "";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(window.location.pathname.split('/').length > 1){
          langague = "/" + splitWindowLocation[1]
          redirectString += langague
        }
        return (
            <Redirect to={redirectString} />
        )
      } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)){
        this.logout();
      } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)){
        this.logout();
      }

      if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading} backgroundImage={backgroundImage}/>
        )
      }

      let renderMainScreen;
      if(!this.state.questionAnswered && this.state.currentGameId && this.state.firstStart && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      } else if(this.state.questionAnswered && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      } else {
        renderMainScreen = this.renderHoldingScreen()
      }
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            {this.state.startConfetti &&
              <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
            }
            <div className="flex-content-container-home">
              <div className="intro-container-home" style={{margin: "auto"}}>
                <div className="grid-wrapper">
                  <div className="flex-header-home" style={{marginTop:10}}>
                    <div style={{visibility: rulesShowInApp? "visible":"hidden"}}>
                      <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                    </div>
                    <div>
                      <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.LOGOUTTEXT}</button>
                    </div>
                  </div>
                  {renderMainScreen}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
              <span style={{fontSize:25}}>
                  {rulesShowInAppPopUpHeader}
              </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Main_App;
